import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { PageType } from './types';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { removeNotification } from './redux/slices/notificationSlice';

import Notifications from './components/utils/Notifications';
import Sidebar from './components/sidebar/Sidebar';
import ProtectedRoute from './pages/ProtectedRoute';
import Home from './pages/Home';
import Employees from './pages/Employees';
import Statistics from './pages/Statistics';
import Levels from './pages/Levels';
import LevelGroups from './pages/LevelGroups';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import ActionsPopup from './components/popup/ActionsPopup';
import { ActionsPopupProvider } from './context/ActionsPopupContext';
import Popup from './components/popup/Popup';
import { PopupProvider } from './context/PopupContext';
import Videogame from './pages/Videogame';
import Settings from './pages/Settings';

function AppProviders({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <PopupProvider>
        <ActionsPopupProvider>
          {children}
        </ActionsPopupProvider>
      </PopupProvider>
    </Provider>
  );
}

function AppComponents() {
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.notification);

  return (
    <>
      <Notifications
        notifications={notifications}
        removeNotification={(id) => dispatch(removeNotification(id))}
      />

      <ActionsPopup />
      <Popup />
    </>
  );
}

function AppRoutes() {
  const isAuthenticated = useAppSelector((state) => !!state.auth.token);

  return (
    <Router>

      <div className='h-svh flex'>
        <div className='pt-6'>
          <Sidebar />
        </div>

        <div className='flex-1 overflow-auto p-6'>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Home />
              </ProtectedRoute>
            } />
            <Route path="/employees" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Employees type={PageType.ALL} />
              </ProtectedRoute>
            } />
            <Route path="/employees/create" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Employees type={PageType.CREATE} />
              </ProtectedRoute>
            } />
            <Route path="/employees/:id" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Employees type={PageType.SINGLE} />
              </ProtectedRoute>
            } />
            <Route path="/employees/:id/edit" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Employees type={PageType.EDIT} />
              </ProtectedRoute>
            } />
            <Route path="/levels" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Levels type={PageType.ALL} />
              </ProtectedRoute>
            } />
            <Route path="/levels/create" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Levels type={PageType.CREATE} />
              </ProtectedRoute>
            } />
            <Route path="/levels/:id" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Levels type={PageType.SINGLE} />
              </ProtectedRoute>
            } />
            <Route path="/levels/:id/edit" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Levels type={PageType.EDIT} />
              </ProtectedRoute>
            } />
            <Route path="/levels/groups" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <LevelGroups type={PageType.ALL} />
              </ProtectedRoute>
            } />
            <Route path="/levels/groups/create" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <LevelGroups type={PageType.CREATE} />
              </ProtectedRoute>
            } />
            <Route path="/levels/groups/:id" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <LevelGroups type={PageType.SINGLE} />
              </ProtectedRoute>
            } />
            <Route path="/levels/groups/:id/edit" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <LevelGroups type={PageType.EDIT} />
              </ProtectedRoute>
            } />
            <Route path="/statistics" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Statistics />
              </ProtectedRoute>
            } />
            <Route path="/settings" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Settings />
              </ProtectedRoute>
            } />
            <Route path="/videogame" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Videogame />
              </ProtectedRoute>
            } />
          </Routes>
        </div>
      </div>

    </Router>
  );
}

function App() {
  return (
    <AppProviders>
      <AppComponents />
      <AppRoutes />
    </AppProviders>
  );
}

export default App;
