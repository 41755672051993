import React, { useEffect } from 'react';
import PieChart from '../charts/PieChart';
import { colors } from '../../utils/charts-configuration';
import { MdHelp } from 'react-icons/md';
import { showPopup } from '../../redux/slices/popupSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { MessageType } from '../../types';
import { fetchLevelsCompletion } from '../../redux/slices/statisticSlice';
import { addNotification } from '../../redux/slices/notificationSlice';
import Loading from '../utils/Loading';

function LevelsCompletion() {
    const dispatch = useAppDispatch();
    const { levelsCompletion, loadingStatistics, errorStatistics } = useAppSelector((state) => state.statistic);

    const [totalLevels, setTotalLevels] = React.useState(0);

    useEffect(() => {
        dispatch(fetchLevelsCompletion());
    }, []);

    useEffect(() => {
        if (levelsCompletion) {
            const total = levelsCompletion.Passed + levelsCompletion.Failed;
            setTotalLevels(total);
        }
    }, [levelsCompletion]);

    useEffect(() => {
        if (errorStatistics) {
            dispatch(addNotification({ message: errorStatistics, type: MessageType.ERROR, tag: 'errorLevelsCompletion' }));
        }
    }, [errorStatistics]);

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <div className='flex items-center justify-between'>
                <h3 className='h3'>Completamento Livelli</h3>
                <button className='text-primary hover:text-primary-dark' onClick={() =>
                    dispatch(
                        showPopup({
                            type: MessageType.INFO,
                            message: `
                                Conta quanti livelli sono stati superati rispetto al numero totale di tentativi.
                                Non fa distinzione tra i vari tipi di livelli.
                            `,
                        }))}
                >
                    <MdHelp size={24} />
                </button>
            </div>

            <div className='grid grid-cols-3 gap-6 mt-3'>
                {loadingStatistics && (
                    <div className='col-span-3'>
                        <Loading height='300px' />
                    </div>
                )}

                {!loadingStatistics && levelsCompletion && (
                    <>
                        <div className='col-span-1'>
                            <div className='flex flex-col gap-3'>
                                <div className='flex flex-col gap-1 items-center text-center'>
                                    <span className='font-bold'>Superati</span>
                                    <div className='w-3/4 h-2' style={{ backgroundColor: colors.green }}></div>
                                    <span>{levelsCompletion.Passed} / {totalLevels} | {Math.round(levelsCompletion.Passed / totalLevels * 100)}%</span>
                                </div>
                                <div className='flex flex-col gap-1 items-center text-center'>
                                    <span className='font-bold'>Falliti</span>
                                    <div className='w-3/4 h-2' style={{ backgroundColor: colors.red }}></div>
                                    <span>{levelsCompletion.Failed} / {totalLevels} | {Math.round(levelsCompletion.Failed / totalLevels * 100)}%</span>
                                </div>
                            </div>
                        </div>

                        <div className='col-span-2'>
                            <PieChart
                                datasetLabel='Livelli'
                                labels={['Superati', 'Falliti']}
                                data={[levelsCompletion.Passed, levelsCompletion.Failed]}
                                chartData={{
                                    backgroundColor: [colors.green, colors.red],
                                    borderWidth: 1,
                                }}
                            />
                        </div>
                    </>
                )}

            </div>

        </section>
    );
}

export default LevelsCompletion;