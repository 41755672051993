import React, { useEffect, useState } from 'react';
import { MdAdd, MdArrowDownward, MdArrowUpward, MdOutlineClose } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import PaginationControls from '../utils/PaginationControls';
import { CiMenuKebab } from 'react-icons/ci';
import SearchBar from '../utils/SearchBar';
import calculatePopupPosition from '../../utils/calculatePopupPosition';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchEmployees } from '../../redux/slices/employeeSlice';
import { MessageType, PositionType, SortOrder, UserSex } from '../../types';
import Loading from '../utils/Loading';
import IEmployee from '../../types/IEmployee';
import { addNotification } from '../../redux/slices/notificationSlice';
import { hidePopup as hideActionsPopup, showPopup as showActionsPopup } from '../../redux/slices/actionsPopupSlice';
import { useActionsPopup } from '../../context/ActionsPopupContext';
import { usePopup } from '../../context/PopupContext';
import { handleDeleteEmployee, handleResetPassword } from '../../utils/employee-actions';
import ProgressBar from '../utils/ProgressBar';
import Tooltip from '../utils/Tooltip';

function EmployeesTable() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { registerCallback: registerActionsPopupCallback } = useActionsPopup();
    const { registerCallback: registerConfirmationPopupCallback } = usePopup();
    const { employees, pages, loadingEmployees, errorEmployees } = useAppSelector((state) => state.employee);
    const { visible, activeRowIndex } = useAppSelector((state) => state.actionsPopup);
    const { loadingAuth } = useAppSelector((state) => state.auth);

    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [sortColumn, setSortColumn] = useState<string>('Progress');
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASC);

    useEffect(() => {
        dispatch(fetchEmployees({ page, limit: 15, searchTerm, sortColumn, sortOrder }));
    }, [dispatch, page, searchTerm, sortColumn, sortOrder]);

    useEffect(() => {
        if (errorEmployees) {
            dispatch(addNotification({ message: errorEmployees, type: MessageType.ERROR }));
        }
    }, [errorEmployees]);

    const handleIconClick = (e: React.MouseEvent, index: number, employee: IEmployee) => {
        e.stopPropagation();

        if (visible && activeRowIndex === index) {
            // Se il popup è già visibile per questa riga, chiudilo
            dispatch(hideActionsPopup());
            return;
        }

        registerActionsPopupCallback('edit', () => {
            if (employee && employee.Id) {
                navigate(`./${employee.Id}/edit`);
            } else {
                dispatch(addNotification({ message: "Impossibile modificare l'utente", type: MessageType.ERROR }));
            }
        });
        registerActionsPopupCallback('resetPassword', () => handleResetPassword(dispatch, employee, registerConfirmationPopupCallback));
        registerActionsPopupCallback('delete', () => handleDeleteEmployee(dispatch, employee, registerConfirmationPopupCallback));

        // Use the utility function to calculate the position
        const position = calculatePopupPosition(e);

        dispatch(
            showActionsPopup({
                position,
                activeRowIndex: index,
                menu: [
                    { title: 'Modifica', actionId: 'edit', iconId: 'edit', },
                    { title: 'Reset password', actionId: 'resetPassword', iconId: 'reset', },
                    { title: 'Elimina', actionId: 'delete', iconId: 'delete', },
                ],
            })
        );
    };

    const handleSort = (column: string) => {
        if (sortColumn === column) {
            // Inverte l'ordine se la colonna è già selezionata
            setSortOrder((prevOrder) => (prevOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC));
        } else {
            // Imposta una nuova colonna da ordinare
            setSortColumn(column);
            setSortOrder(SortOrder.ASC);
        }
    };

    return (
        <section className='pb-6'>

            <div className='grid grid-cols-3 items-center mb-6'>
                <div className='col-span-1'>
                    <h1 className='h1'>Dipendenti</h1>
                </div>
                <div className='col-span-2 flex items-center gap-5'>
                    <SearchBar value={searchTerm} placeholder='Cerca nome, cognome, reparto, matricola...' onChange={setSearchTerm} />
                    <Link to={'./create'} className='btn'>Aggiungi <MdAdd size={18} /></Link>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        {[
                            { column: null, label: '' },
                            { column: 'Surname', label: 'Cognome' },
                            { column: 'Name', label: 'Nome' },
                            { column: 'Department', label: 'Reparto' },
                            { column: 'Badge', label: 'Matricola' },
                            { column: 'Levels', label: 'Livelli' },
                            { column: 'Progress', label: 'Avanzamento' },
                            { column: 'LatestLogin', label: 'Ultimo accesso' },
                            { column: null, label: '' },
                        ].map((item, index) => (
                            <th key={index}
                                className={`${item.column ? 'has-order' : ''}`}
                                onClick={() => item.column && handleSort(item.column)}
                            >
                                <div className='flex items-center justify-center gap-3'>
                                    {item.label} {item.column && sortColumn === item.column && (
                                        sortOrder === SortOrder.ASC ? <MdArrowDownward size={20} /> : <MdArrowUpward size={20} />
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {loadingEmployees && (
                        <tr>
                            <td colSpan={8}>
                                <Loading height='400px' />
                            </td>
                        </tr>
                    )}
                    {!loadingEmployees && !errorEmployees && employees.map((employee, index) => (
                        <tr key={index}
                            className='hover:bg-gray-200 cursor-pointer'
                            onClick={() => navigate(`./${employee.Id}`)}
                        >
                            <td className='text-lg'>
                                {employee.UserCompany?.Sex === UserSex.MALE ? '👦🏻' :
                                    employee.UserCompany?.Sex === UserSex.FEMALE ? '👧🏻' : 'N/A'}
                            </td>
                            <td>{employee.UserCompany.Surname}</td>
                            <td>{employee.UserCompany.Name}</td>
                            <td>{employee.Department}</td>
                            <td>{employee.Badge}</td>
                            <td>{employee.Levels.Passed} ✅ | {employee.Levels.Waiting} 🕒 | {employee.Levels.Failed} ❌</td>
                            <td>
                                <Tooltip text={employee.Progress + '%'} position={PositionType.TOP}>
                                    <ProgressBar progress={employee.Progress + '%'} />
                                </Tooltip>
                            </td>
                            <td>{employee.UserCompany.Account.LatestLogin ?? 'N/A'}</td>
                            <td>
                                <button onClick={(e) => handleIconClick(e, index, employee)}>
                                    {visible && activeRowIndex === index ? (
                                        <MdOutlineClose size={28} /> // Mostra l'icona "X" se il popup è visibile per questa riga
                                    ) : (
                                        <CiMenuKebab size={28} /> // Mostra i tre puntini altrimenti
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                    {!loadingEmployees && !errorEmployees && employees.length === 0 && (
                        <tr>
                            <td colSpan={8} className='italic text-gray-600'>Nessun risultato trovato...</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <PaginationControls page={page} totalPages={pages} onPageChange={(newPage) => setPage(newPage)} />

            {loadingAuth && // Mostra il loader se l'utente sta resettando la password
                <div className='flex justify-end'>
                    <Loading width='200px' height='0' />
                </div>
            }
        </section>
    );
}

export default EmployeesTable;
