import React, { useEffect } from 'react';
import PieChart from '../charts/PieChart';
import { colors } from '../../utils/charts-configuration';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { showPopup } from '../../redux/slices/popupSlice';
import { MessageType } from '../../types';
import { MdHelp } from 'react-icons/md';
import { addNotification } from '../../redux/slices/notificationSlice';
import { fetchLevelsGoals } from '../../redux/slices/statisticSlice';
import { mapGoalLevelToText } from '../../utils/functions';
import Loading from '../utils/Loading';

function LevelsGoals() {
    const dispatch = useAppDispatch();
    const { levelsGoals, loadingStatistics, errorStatistics } = useAppSelector((state) => state.statistic);

    const [totalLevels, setTotalLevels] = React.useState(0);

    useEffect(() => {
        dispatch(fetchLevelsGoals());
    }, []);

    useEffect(() => {
        if (levelsGoals) {
            const total = levelsGoals.reduce((acc, item) => acc + item.Value, 0);
            setTotalLevels(total);
        }
    }, [levelsGoals]);

    useEffect(() => {
        if (errorStatistics) {
            dispatch(addNotification({ message: errorStatistics, type: MessageType.ERROR, tag: 'errorLevelsGoals' }));
        }
    }, [errorStatistics]);

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <div className='flex items-center justify-between'>
                <h3 className='h3'>Obiettivi Livelli</h3>
                <button className='text-primary hover:text-primary-dark' onClick={() =>
                    dispatch(
                        showPopup({
                            type: MessageType.INFO,
                            message: `
                                Obiettivi dei livelli che sono stati creati e assegnati ai dipendenti.
                                Ogni livello ha un obiettivo che deve essere raggiunto per completare il livello.
                                Il grafico a torta mostra la percentuale di distribuzione degli obiettivi.
                            `,
                        }))}
                >
                    <MdHelp size={24} />
                </button>
            </div>

            <div className='grid grid-cols-3 gap-6 mt-3'>
                {loadingStatistics && (
                    <div className='col-span-3'>
                        <Loading height='300px' />
                    </div>
                )}

                {!loadingStatistics && levelsGoals && levelsGoals.length > 0 && (
                    <>
                        <div className='col-span-1'>
                            <div className='flex flex-col gap-3'>
                                {levelsGoals.map((item, index) => (
                                    <div key={index} className='flex flex-col gap-1 items-center text-center'>
                                        <span className='font-bold'>{mapGoalLevelToText(item.Goal)}</span>
                                        <div className='w-3/4 h-2' style={{ backgroundColor: Object.values(colors)[index] }}></div>
                                        <p>{item.Value} / {totalLevels} | {Math.round(item.Value / totalLevels * 100)}%</p>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='col-span-2'>
                            <PieChart
                                datasetLabel='Livelli'
                                labels={levelsGoals.map(item => mapGoalLevelToText(item.Goal))}
                                data={levelsGoals.map(item => item.Value)}
                            />
                        </div>
                    </>
                )}

            </div>

        </section>
    );
}

export default LevelsGoals;