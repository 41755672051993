import { StatusEnum } from ".";
import IEmployee from "./IEmployee";
import ILevel from "./ILevel";

export default interface ILevelGroup {
    Id: number;
    Id_Company: number;
    Name: string;
    Description: string | null;
    Status: StatusEnum;
    Creation: string;
    Timestamp: string;

    //* NotMapped
    Levels: ILevel[];
    Employees: IEmployee[];
    Progress: number;
}

export const exampleLevelGroup: ILevelGroup = {
    Id: 0,
    Id_Company: 0,
    Name: '',
    Description: null,
    Status: StatusEnum.DRAFT,
    Creation: '',
    Timestamp: '',

    Levels: [],
    Employees: [],
    Progress: 0,
};