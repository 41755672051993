import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { chartDataset, chartOptions, ChartProps } from '../../utils/charts-configuration';

Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function BarChart({ datasetLabel, labels, data }: ChartProps) {
  const barData = {
    labels,
    datasets: [
      {
        label: datasetLabel ?? 'Dataset',
        data,
        ...chartDataset,
      },
    ],
  };

  const options = {
    ...chartOptions,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="w-fit mx-auto">
      <Bar data={barData} options={options} />
    </div>
  );
};

export default BarChart;
