import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { chartDataset, chartOptions, ChartProps } from '../../utils/charts-configuration';

Chart.register(ArcElement, Tooltip, Legend);

function PieChart({ datasetLabel, labels, data, chartData = chartDataset }: ChartProps) {
  const pieData = {
    labels,
    datasets: [
      {
        label: datasetLabel ?? 'Dataset',
        data,
        ...chartData,
      },
    ],
  };

  const options = {
    ...chartOptions,
  };

  return (
    <div className="w-fit mx-auto">
      <Pie data={pieData} options={options} />
    </div>
  );
};

export default PieChart;
