import React, { useEffect, useState } from 'react';
import PaginationControls from '../utils/PaginationControls';
import { MdOpenInBrowser, MdOutlineClose, MdOutlineCopyAll } from 'react-icons/md';
import { CiMenuKebab } from 'react-icons/ci';
import { Link, useNavigate } from 'react-router-dom';
import calculatePopupPosition from '../../utils/calculatePopupPosition';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addNotification } from '../../redux/slices/notificationSlice';
import { MessageType, StatusEnum } from '../../types';
import { hidePopup, showPopup } from '../../redux/slices/actionsPopupSlice';
import { useActionsPopup } from '../../context/ActionsPopupContext';
import { copyLinkToClipboard } from '../../utils/functions';
import Loading from '../utils/Loading';
import ILevel from '../../types/ILevel';
import { fetchLevels } from '../../redux/slices/levelSlice';
import { usePopup } from '../../context/PopupContext';
import { handleDeleteLevel, handlePublishLevel } from '../../utils/level-actions';

function LevelsToPublish() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { registerCallback: registerActionsPopupCallback } = useActionsPopup();
    const { registerCallback: registerConfirmationPopupCallback } = usePopup();
    const { visible, activeRowIndex } = useAppSelector((state) => state.actionsPopup);
    const { levels, pages, loadingLevels, errorLevels } = useAppSelector((state) => state.level);

    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(fetchLevels({ page, limit: 5, onlyDrafts: true }));
    }, [dispatch, page]);

    useEffect(() => {
        if (errorLevels) {
            dispatch(addNotification({ message: errorLevels, type: MessageType.ERROR, tag: 'errorLevels' }));
        }
    }, [errorLevels]);

    const handleIconClick = (e: React.MouseEvent, index: number, level: ILevel) => {
        e.stopPropagation();

        if (visible && activeRowIndex === index) {
            // Se il popup è già visibile per questa riga, chiudilo
            dispatch(hidePopup());
            return;
        }

        registerActionsPopupCallback('edit', () => {
            if (level && level.Id) {
                navigate(`/levels/${level.Id}/edit`);
            } else {
                dispatch(addNotification({ message: "Impossibile modificare il livello", type: MessageType.ERROR }));
            }
        });
        registerActionsPopupCallback('publish', () => handlePublishLevel(dispatch, level, registerConfirmationPopupCallback));
        registerActionsPopupCallback('stats', () => dispatch(addNotification({ message: 'statistiche', type: MessageType.INFO })));
        registerActionsPopupCallback('delete', () => handleDeleteLevel(dispatch, level, registerConfirmationPopupCallback));

        // Use the utility function to calculate the position
        const position = calculatePopupPosition(e);

        dispatch(
            showPopup({
                position,
                activeRowIndex: index,
                menu: [
                    { title: 'Modifica', actionId: 'edit', iconId: 'edit', },
                    { title: 'Pubblica', actionId: 'publish', iconId: 'share', },
                    { title: 'Statistiche', actionId: 'stats', iconId: 'stats', },
                    { title: 'Elimina', actionId: 'delete', iconId: 'delete', },
                ],
            })
        );
    };

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <div className='w-full flex items-center justify-between'>
                <h3 className='h3'>Livelli da pubblicare</h3>
                <Link to={'/levels'} className='btn'>Livelli</Link>
            </div>

            <table className='mt-3'>
                <thead>
                    <tr>
                        <th>Nome</th>
                        {/* <th>Stato</th> */}
                        <th>Obiettivo</th>
                        <th>Pacchetti</th>
                        <th>Dipendenti</th>
                        <th>Stanze</th>
                        <th>Link Test</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {loadingLevels && (
                        <tr>
                            <td colSpan={8}>
                                <Loading height='400px' />
                            </td>
                        </tr>
                    )}
                    {!loadingLevels && !errorLevels && levels.map((level, index) => (
                        <tr key={index}
                            className='hover:bg-gray-200 cursor-pointer'
                            onClick={() => { navigate(`/levels/${level.Id}`) }}
                        >
                            <td>{level.Name}</td>
                            {/* <td>{level.Status} {level.Status === StatusEnum.PUBLISHED ? '✅' : '🕒'}</td> */}
                            <td>{level.Goal}</td>
                            <td>{level.LevelGroups.length} {level.LevelGroups.length == 0 && '❌'}</td>
                            <td>{level.Employees.length} {level.Employees.length == 0 && '❌'}</td>
                            <td>{level.RoomsNumber} {level.RoomsNumber == 0 && '❌'}</td>
                            <td className='flex items-center justify-center gap-3'>
                                <Link to={level.Link} target='_blank' onClick={(e) => e.stopPropagation()}>
                                    <MdOpenInBrowser size={28} />
                                </Link>
                                <button onClick={(e) => copyLinkToClipboard(dispatch, level.Link, e)}>
                                    <MdOutlineCopyAll size={28} />
                                </button>
                            </td>
                            <td>
                                <button onClick={(e) => handleIconClick(e, index, level)}>
                                    {visible && activeRowIndex === index ? (
                                        <MdOutlineClose size={28} /> // Mostra l'icona "X" se il popup è visibile per questa riga
                                    ) : (
                                        <CiMenuKebab size={28} /> // Mostra i tre puntini altrimenti
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                    {!loadingLevels && !errorLevels && levels.length === 0 && (
                        <tr>
                            <td colSpan={8} className='italic text-gray-600'>Nessun risultato trovato...</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <PaginationControls page={page} totalPages={pages} onPageChange={setPage} />

        </section>
    );
}

export default LevelsToPublish;
