import { addNotification } from "../redux/slices/notificationSlice";
import { AppDispatch } from "../redux/store";
import { GoalLevel, MessageType } from "../types";

export function isValidCF(cf: string): boolean {
  if (!cf || cf.length !== 16) return false;
  const cfRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
  return cfRegex.test(cf);
}

export function isValidEmail(email: string): boolean {
  if (!email) return false;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export function isValidPassword(password: string): boolean {
  if (!password) return false;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,}$/;
  return passwordRegex.test(password);
}

export function isValidPhone(phone: string): boolean {
  if (!phone) return false;
  const phoneRegex = /^\+\d{5,15}$/;
  return phoneRegex.test(phone);
}

export function isToday(date: Date): boolean {
  const today = new Date();
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
}

export function isTodayStr(dateStr: string): boolean {
  const date = new Date(dateStr);
  return isToday(date);
}

export function todayStr(): string {
  const today = new Date();
  return today.toISOString().split('T')[0];
}

export const copyLinkToClipboard = (
  dispatch: AppDispatch,
  link: string,
  e?: React.MouseEvent,
) => {
  e?.stopPropagation();

  navigator.clipboard.writeText(link)
    .then(() => {
      dispatch(addNotification({ message: 'Link copiato negli appunti', type: MessageType.SUCCESS }));
    })
    .catch((error) => {
      dispatch(addNotification({ message: `Errore nella copia del link: ${error}`, type: MessageType.ERROR }));
    });
};

export const mapGoalLevelToText = (goal: GoalLevel) => {
  switch (goal) {
    case GoalLevel.Quiz:
      return 'Quiz';
    case GoalLevel.RaccoltaOggetti:
      return 'Raccolta Oggetti';
    case GoalLevel.RaggiungiStanza:
      return 'Raggiungi Stanza';
    case GoalLevel.StanzaOggetti:
      return 'Stanza con Oggetti';
    case GoalLevel.Deposito:
      return 'Deposito Oggetti';
  }
};

export function formatTime(seconds: number, format: string): string {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  let formattedTime = format;
  if (format.includes('HH')) {
    formattedTime = formattedTime.replace('HH', String(hrs).padStart(2, '0'));
  }
  if (format.includes('MM')) {
    formattedTime = formattedTime.replace('MM', String(mins).padStart(2, '0'));
  }
  if (format.includes('SS')) {
    formattedTime = formattedTime.replace('SS', String(secs).padStart(2, '0'));
  }

  return formattedTime;
}
