import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import ILevelsGoals from '../../types/ILevelsGoals';
import ILevelsCompletion, { exampleLevelsCompletion } from '../../types/ILevelsCompletion';
import IPassedLevels from '../../types/IPassedLevels';
import ILevelsTimes from '../../types/ILevelsTimes';
import IGeneralStats, { exampleGeneralStats } from '../../types/IGeneralStats';
import ILevelGroupProgress from '../../types/ILevelGroupProgress';
import ILatestLoginsProgress from '../../types/ILatestLoginsProgress';

interface IState {
  pages: number;
  levelsGoals: ILevelsGoals[];
  levelsCompletion: ILevelsCompletion;
  passedLevels: IPassedLevels[];
  completionTimes: ILevelsTimes[];
  averageTimes: ILevelsTimes[];
  generalStats: IGeneralStats;
  levelGroupsProgress: ILevelGroupProgress[];
  latestLoginsProgress: ILatestLoginsProgress[];
  loadingStatistics: boolean;
  errorStatistics: string | null;
}

const initialState: IState = {
  pages: 0,
  levelsGoals: [],
  levelsCompletion: exampleLevelsCompletion,
  passedLevels: [],
  completionTimes: [],
  averageTimes: [],
  generalStats: exampleGeneralStats,
  levelGroupsProgress: [],
  latestLoginsProgress: [],
  loadingStatistics: false,
  errorStatistics: null,
};

// Fetch levels goals
export const fetchLevelsGoals = createAsyncThunk(
  'statistic/fetchLevelsGoals',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/statistics/levels/goals`);
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero degli obiettivi dei livelli');
    }
  }
);

// Fetch levels completion
export const fetchLevelsCompletion = createAsyncThunk(
  'statistic/fetchLevelsCompletion',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/statistics/levels/completion`);
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero del completamento dei livelli');
    }
  }
);

// Fetch passed levels
export const fetchPassedLevels = createAsyncThunk(
  'statistic/fetchPassedLevels',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/statistics/levels/passed`);
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero dei livelli superati');
    }
  }
);

// Fetch completion times
export const fetchCompletionTimes = createAsyncThunk(
  'statistic/fetchCompletionTimes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/statistics/levels/completion-times`);
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero dei tempi totali');
    }
  }
);

// Fetch average times
export const fetchAverageTimes = createAsyncThunk(
  'statistic/fetchAverageTimes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/statistics/levels/average-times`);
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero dei tempi medi');
    }
  }
);

// Fetch general stats
export const fetchGeneralStats = createAsyncThunk(
  'statistic/fetchGeneralStats',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/statistics/general`);
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero delle statistiche generali');
    }
  }
);

// Fetch level groups progress
export const fetchLevelGroupsProgress = createAsyncThunk(
  'statistic/fetchLevelGroupsProgress',
  async ({ page, limit }: { page: number, limit: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/statistics/levels/groups`, {
        params: { page, limit },
      });
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero dei progressi dei pacchetti di livelli');
    }
  }
);

// Fetch latest logins progress
export const fetchLatestLoginsProgress = createAsyncThunk(
  'statistic/fetchLatestLoginsProgress',
  async ({ page, limit }: { page: number, limit: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/statistics/employees`, {
        params: { page, limit },
      });
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero dei progressi degli ultimi accessi dei dipendenti');
    }
  }
);

const statisticSlice = createSlice({
  name: 'statistic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //* Fetch levels goals
      .addCase(fetchLevelsGoals.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchLevelsGoals.fulfilled, (state, action) => {
        state.loadingStatistics = false;
        state.levelsGoals = action.payload as ILevelsGoals[];
      })
      .addCase(fetchLevelsGoals.rejected, (state, action) => {
        state.loadingStatistics = false;
        state.levelsGoals = [];
        state.errorStatistics = action.payload as string || 'Failed to fetch levelsGoals';
      })
      //* Fetch levels completion
      .addCase(fetchLevelsCompletion.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchLevelsCompletion.fulfilled, (state, action) => {
        state.loadingStatistics = false;
        state.levelsCompletion = action.payload as ILevelsCompletion;
      })
      .addCase(fetchLevelsCompletion.rejected, (state, action) => {
        state.loadingStatistics = false;
        state.levelsCompletion = exampleLevelsCompletion;
        state.errorStatistics = action.payload as string || 'Failed to fetch levelsCompletion';
      })
      //* Fetch passed levels
      .addCase(fetchPassedLevels.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchPassedLevels.fulfilled, (state, action) => {
        state.loadingStatistics = false;
        state.passedLevels = action.payload as IPassedLevels[];
      })
      .addCase(fetchPassedLevels.rejected, (state, action) => {
        state.loadingStatistics = false;
        state.passedLevels = [];
        state.errorStatistics = action.payload as string || 'Failed to fetch passedLevels';
      })
      //* Fetch completion times
      .addCase(fetchCompletionTimes.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchCompletionTimes.fulfilled, (state, action) => {
        state.loadingStatistics = false;
        state.completionTimes = action.payload as ILevelsTimes[];
      })
      .addCase(fetchCompletionTimes.rejected, (state, action) => {
        state.loadingStatistics = false;
        state.completionTimes = [];
        state.errorStatistics = action.payload as string || 'Failed to fetch completionTimes';
      })
      //* Fetch average times
      .addCase(fetchAverageTimes.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchAverageTimes.fulfilled, (state, action) => {
        state.loadingStatistics = false;
        state.averageTimes = action.payload as ILevelsTimes[];
      })
      .addCase(fetchAverageTimes.rejected, (state, action) => {
        state.loadingStatistics = false;
        state.averageTimes = [];
        state.errorStatistics = action.payload as string || 'Failed to fetch averageTimes';
      })
      //* Fetch general stats
      .addCase(fetchGeneralStats.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchGeneralStats.fulfilled, (state, action) => {
        state.loadingStatistics = false;
        state.generalStats = action.payload as IGeneralStats;
      })
      .addCase(fetchGeneralStats.rejected, (state, action) => {
        state.loadingStatistics = false;
        state.generalStats = exampleGeneralStats;
        state.errorStatistics = action.payload as string || 'Failed to fetch averageTimes';
      })
      //* Fetch level groups progress
      .addCase(fetchLevelGroupsProgress.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchLevelGroupsProgress.fulfilled, (state, action) => {
        state.loadingStatistics = false;
        state.levelGroupsProgress = action.payload.items as ILevelGroupProgress[];
        state.pages = action.payload.pages as number;
      })
      .addCase(fetchLevelGroupsProgress.rejected, (state, action) => {
        state.loadingStatistics = false;
        state.levelGroupsProgress = [];
        state.pages = 0;
        state.errorStatistics = action.payload as string || 'Failed to fetch averageTimes';
      })
      //* Fetch latest logins progress
      .addCase(fetchLatestLoginsProgress.pending, (state) => {
        state.loadingStatistics = true;
        state.errorStatistics = null;
      })
      .addCase(fetchLatestLoginsProgress.fulfilled, (state, action) => {
        state.loadingStatistics = false;
        state.latestLoginsProgress = action.payload.items as ILatestLoginsProgress[];
        state.pages = action.payload.pages as number;
      })
      .addCase(fetchLatestLoginsProgress.rejected, (state, action) => {
        state.loadingStatistics = false;
        state.latestLoginsProgress = [];
        state.pages = 0;
        state.errorStatistics = action.payload as string || 'Failed to fetch averageTimes';
      });
  },
});

export default statisticSlice.reducer;
