import React from 'react';
import logo_tiled from '../images/logo_tiled.png';
import tiled_example from '../images/tiled_example.jpg';
import win_10 from '../images/os/win_10.png';
import win_7 from '../images/os/win_7.png';
import mac_os from '../images/os/mac_os.png';
import linux from '../images/os/linux.png';
import { Link } from 'react-router-dom';
import { MdDownload } from 'react-icons/md';

function Settings() {
	return (
		<section className='w-full h-full'>
			<h1 className='h1'>Impostazioni</h1>

			<div className='grid grid-cols-3 gap-6 py-6'>

				<div className='col-span-2'>
					<section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg text-center'>
						<h3 className='h3'>Programma creazione stanze</h3>
						<img src={logo_tiled} alt='Tiled' className='w-2/3 h-auto my-5 mx-auto' />
						<div className='grid grid-cols-4 gap-4'>
							{[
								{ name: 'Windows 10+', image: win_10, link: 'Tiled-1.11.1_Windows-10+_x86_64.msi' },
								{ name: 'Windows 7', image: win_7, link: 'Tiled-1.11.1_Windows-7-8_x86.msi' },
								{ name: 'MacOS', image: mac_os, link: 'tiled-macos.zip' },
								{ name: 'Linux', image: linux, link: 'Tiled-1.11.1_Linux_Qt-6_x86_64.AppImage' },
							].map((item, index) => (
								<Link key={index} to={`/download/${item.link}`} target='_blank'
									className='col-span-1 border border-primary rounded-lg p-3 hover:bg-primary hover:text-white flex flex-col items-center justify-center gap-4'>
									<img src={item.image} alt={item.name} className='w-1/2 h-auto mx-auto' />
									<p>{item.name}</p>
								</Link>
							))}
						</div>
					</section>
				</div>

				<div className='col-span-1'>
					<section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg text-center'>
						<h3 className='h3'>Esempio Stanza</h3>
						<p className='mt-3'>
							Scarica questa stanza che contiene le configurazioni base che ti servono per iniziare a creare le tue stanze.
							Non modificare i file contenuti nella cartella <code>images</code> e importa il file <code>000_example.json</code> nel programma Tiled.
							<br />
							Una volta terminata la creazione della stanza salva le tue modifiche in un nuovo file JSON (questo file sarà quello da caricare durante la creazione del livello).
						</p>
						<img src={tiled_example} alt='Tiled' className='w-full h-auto my-5 mx-auto' />
						<Link to={'/download/PlayTraining.zip'} target='_blank' className='btn btn-lg mx-auto'>Scarica <MdDownload size={20} /></Link>
					</section>
				</div>

			</div>

		</section>
	);
}

export default Settings;
