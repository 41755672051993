import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import ILevel from '../../types/ILevel';
import { SortOrder, StatusEnum } from '../../types';
import IQuiz from '../../types/IQuiz';

interface IState {
  levels: ILevel[];
  pages: number;
  selectedLevel: ILevel | null;
  newLevel: ILevel | null
  loadingLevels: boolean;
  errorLevels: string | null;
}

const initialState: IState = {
  levels: [],
  pages: 0,
  selectedLevel: null,
  newLevel: null,
  loadingLevels: false,
  errorLevels: null,
};

// Fetch all levels
export const fetchLevels = createAsyncThunk(
  'level/fetchLevels',
    async (
      { page, limit, searchTerm, sortColumn, sortOrder, onlyDrafts = false }: { page: number, limit: number, searchTerm?: string, sortColumn?: string, sortOrder?: SortOrder, onlyDrafts?: boolean },
      { rejectWithValue }
    ) => {
      try {
      const response = await axiosInstance.get(`/levels${searchTerm ? `?s=${searchTerm}` : ''}`, {
        params: { page, limit, sortColumn, sortOrder, onlyDrafts },
      });

      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero dei level');
    }
  }
);

// Fetch a single level by id
export const fetchLevelById = createAsyncThunk(
  'level/fetchLevelById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/levels/${id}`);
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero del level');
    }
  }
);

// Create a new level
export const createLevel = createAsyncThunk(
  'level/createLevel',
  async (
    { level, levelGroups, rooms, updatedRoomIndexes, quiz }: {
      level: ILevel,
      levelGroups: string[],
      rooms: File[],
      updatedRoomIndexes: number[],
      quiz: IQuiz,
    },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append('level', JSON.stringify(level));
      formData.append('levelGroups', JSON.stringify(levelGroups));
      formData.append('updatedRoomIndexes', JSON.stringify(updatedRoomIndexes));
      formData.append('quiz', JSON.stringify(quiz));
      rooms.forEach((file, index) => {
        formData.append('rooms', file);
      });
      const response = await axiosInstance.post('/levels', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante la creazione del level');
    }
  }
);

// Update an existing level
export const updateLevel = createAsyncThunk(
  'level/updateLevel',
  async (
    { id, level, levelGroups, existingIdRooms, rooms, updatedRoomIndexes, quiz }: {
      id: number,
      level: ILevel,
      levelGroups: string[],
      existingIdRooms: number[],
      rooms: File[],
      updatedRoomIndexes: number[],
      quiz: IQuiz,
    },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append('level', JSON.stringify(level));
      formData.append('levelGroups', JSON.stringify(levelGroups));
      formData.append('existingIdRooms', JSON.stringify(existingIdRooms));
      formData.append('updatedRoomIndexes', JSON.stringify(updatedRoomIndexes));
      formData.append('quiz', JSON.stringify(quiz));
      rooms.forEach((file, index) => {
        formData.append('rooms', file);
      });
      const response = await axiosInstance.put(`/levels/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante l\'aggiornamento del level');
    }
  }
);

// Delete an existing level
export const deleteLevel = createAsyncThunk(
  'level/deleteLevel',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/levels/${id}`);
      return { ...response.data, Id_Level: id };
    } catch (error: any) {
      console.log('error', error);
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante la pubblicazione del level');
    }
  }
);

// Publish a level
export const publishLevel = createAsyncThunk(
  'level/publishLevel',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/levels/${id}/publish`);
      return response.data;
    } catch (error: any) {
      console.log('error', error);
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante la pubblicazione del level');
    }
  }
);

const levelSlice = createSlice({
  name: 'level',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //* Fetch all levels
      .addCase(fetchLevels.pending, (state) => {
        state.loadingLevels = true;
        state.errorLevels = null;
      })
      .addCase(fetchLevels.fulfilled, (state, action) => {
        state.loadingLevels = false;
        state.levels = action.payload.items;
        state.pages = action.payload.pages;
      })
      .addCase(fetchLevels.rejected, (state, action) => {
        state.loadingLevels = false;
        state.levels = [];
        state.pages = 0;
        state.errorLevels = action.payload as string || 'Failed to fetch levels';
      })
      //* Fetch a single level by id
      .addCase(fetchLevelById.pending, (state) => {
        state.loadingLevels = true;
        state.errorLevels = null;
      })
      .addCase(fetchLevelById.fulfilled, (state, action) => {
        state.loadingLevels = false;
        state.selectedLevel = action.payload;
      })
      .addCase(fetchLevelById.rejected, (state, action) => {
        state.loadingLevels = false;
        state.selectedLevel = null;
        state.errorLevels = action.payload as string || 'Failed to fetch the level';
      })
      //* Create a new level
      .addCase(createLevel.pending, (state) => {
        state.loadingLevels = true;
        state.errorLevels = null;
      })
      .addCase(createLevel.fulfilled, (state, action) => {
        state.loadingLevels = false;
        state.newLevel = action.payload;
      })
      .addCase(createLevel.rejected, (state, action) => {
        state.loadingLevels = false;
        state.errorLevels = action.payload as string || 'Failed to create level';
      })
      //* Update an existing level
      .addCase(updateLevel.pending, (state) => {
        state.loadingLevels = true;
        state.errorLevels = null;
      })
      .addCase(updateLevel.fulfilled, (state, action) => {
        state.loadingLevels = false;
      })
      .addCase(updateLevel.rejected, (state, action) => {
        state.loadingLevels = false;
        state.errorLevels = action.payload as string || 'Failed to update level';
      })
      //* Delete an existing level
      .addCase(deleteLevel.pending, (state) => {
        state.loadingLevels = true;
        state.errorLevels = null;
      })
      .addCase(deleteLevel.fulfilled, (state, action) => {
        state.loadingLevels = false;
        state.selectedLevel = null;
        state.levels = state.levels.filter((level) => level.Id !== action.payload.Id_Level);
      })
      .addCase(deleteLevel.rejected, (state, action) => {
        state.loadingLevels = false;
        state.errorLevels = action.payload as string || 'Failed to delete level';
      })
      //* Publish a level
      .addCase(publishLevel.pending, (state) => {
        state.loadingLevels = true;
        state.errorLevels = null;
      })
      .addCase(publishLevel.fulfilled, (state, action) => {
        state.loadingLevels = false;
        if (state.selectedLevel && state.selectedLevel.Id === action.meta.arg) {
          state.selectedLevel = {
            ...state.selectedLevel,
            Status: StatusEnum.PUBLISHED,
          };
        }
        state.levels = state.levels.map(level =>
          level.Id === action.meta.arg
            ? { ...level, Status: StatusEnum.PUBLISHED }
            : level
        );
      })
      .addCase(publishLevel.rejected, (state, action) => {
        state.loadingLevels = false;
        state.errorLevels = action.payload as string || 'Failed to publish level';
      });
  },
});

export default levelSlice.reducer;
