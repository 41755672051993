import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { MessageType } from '../../types';
import { fetchAverageTimes } from '../../redux/slices/statisticSlice';
import { addNotification } from '../../redux/slices/notificationSlice';
import TimeStatistics from './TimeStatistics';

function AverageTimes() {
    const dispatch = useAppDispatch();
    const { averageTimes, loadingStatistics, errorStatistics } = useAppSelector((state) => state.statistic);

    useEffect(() => {
        dispatch(fetchAverageTimes());
    }, []);

    useEffect(() => {
        if (errorStatistics) {
            dispatch(addNotification({ message: errorStatistics, type: MessageType.ERROR, tag: 'errorAverageTimes' }));
        }
    }, [errorStatistics]);

    return (
        <TimeStatistics
            title="Tempi medi di completamento"
            data={averageTimes}
            loading={loadingStatistics}
            popupMessage="Mostra il tempo medio impiegato per completare tutti i livelli di un tipo.
                            Non fa distinzione tra livelli superati e livelli falliti."
        />
    );
}

export default AverageTimes;