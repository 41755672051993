export interface ChartProps {
    datasetLabel?: string;
    labels: string[];
    data: number[];
    chartData?: { backgroundColor: string[], borderWidth: number };
}

export const colors = {
    red: '#ff6384',
    blue: '#36a2eb',
    yellow: '#ffce56',
    green: '#22c55e',
    purple: '#9966ff',
    orange: '#ff9f40',
    teal: '#4bc0c0',
}

export const chartOptions = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
        legend: {
            display: false,
            position: 'top' as const,
            onClick: () => { },
        },
        tooltip: {
            enabled: true,
        },
    },
}

export const chartDataset = {
    backgroundColor: Object.values(colors).map(color => color),
    borderWidth: 1,
}
