import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IPosition, { examplePosition } from '../../types/IPosition';

interface IState {
    visible: boolean;
    position: IPosition;
    activeRowIndex: number | null;
    menu: { title: string; actionId: string; iconId: string }[];
}

const initialState: IState = {
    visible: false,
    position: examplePosition,
    activeRowIndex: null,
    menu: [],
};

const actionsPopupSlice = createSlice({
    name: 'actionsPopup',
    initialState,
    reducers: {
        showPopup: (state, action: PayloadAction<Omit<IState, 'visible'>>) => {
            return { ...action.payload, visible: true };
        },
        hidePopup: (state) => {
            state.visible = false;
            state.activeRowIndex = null;
        },
    },
});

export const { showPopup, hidePopup } = actionsPopupSlice.actions;
export default actionsPopupSlice.reducer;
