import React from 'react';
import LatestLoginsProgress from '../components/statistics/LatestLoginsProgress';
import LevelsCompletion from '../components/statistics/LevelsCompletion';
import LevelsGoals from '../components/statistics/LevelsGoals';
import LevelsToPublish from '../components/levels/LevelsToPublish';
import AverageTimes from '../components/statistics/AverageTimes';

function Home() {
	return (
		<section className='w-full h-full'>
			<h1 className='h1'>Dashboard</h1>

			<div className='grid grid-cols-2 gap-6 py-6'>

				<div className='col-span-1'>
					<LatestLoginsProgress />
				</div>

				<div className='col-span-1'>
					<LevelsCompletion />
				</div>

				<div className='col-span-1'>
					<AverageTimes />
				</div>

				<div className='col-span-1'>
					<LevelsGoals />
				</div>

				<div className='col-span-2'>
					<LevelsToPublish />
				</div>

			</div>

		</section>
	);
}

export default Home;
