import React from 'react';
import LatestLoginsProgress from '../components/statistics/LatestLoginsProgress';
import LevelsCompletion from '../components/statistics/LevelsCompletion';
import CompletionTimes from '../components/statistics/CompletionTimes';
import LevelsGoals from '../components/statistics/LevelsGoals';
import LevelsToPublish from '../components/levels/LevelsToPublish';
import TopFastestLevels from '../components/statistics/TopFastestLevels';
import GeneralStats from '../components/statistics/GeneralStats';
import LevelGroupsProgress from '../components/statistics/LevelGroupsProgress';
import AverageTimes from '../components/statistics/AverageTimes';
import PassedLevels from '../components/statistics/PassedLevels';

function Statistics() {
	return (
		<section className='w-full h-full'>
			<h1 className='h1'>Statistiche Globali</h1>

			<div className='grid grid-cols-2 gap-6 py-6'>

				<div className='col-span-1'>
					<LevelsGoals />
				</div>

				<div className='col-span-1'>
					<GeneralStats />
				</div>

				<div className='col-span-1'>
					<CompletionTimes />
				</div>

				<div className='col-span-1'>
					<AverageTimes />
				</div>

				<div className='col-span-1'>
					<LatestLoginsProgress />
				</div>

				<div className='col-span-1'>
					<LevelGroupsProgress />
					{/* <TopFastestLevels /> */}
				</div>

				<div className='col-span-1'>
					<PassedLevels />
				</div>

				<div className='col-span-1'>
					<LevelsCompletion />
				</div>

			</div>

		</section>
	);
}

export default Statistics;
