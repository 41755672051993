import { configureStore } from '@reduxjs/toolkit';
import notificationReducer from './slices/notificationSlice';
import actionsPopUpReducer from './slices/actionsPopupSlice';
import popupReducer from './slices/popupSlice';
import authReducer from './slices/authSlice';
import accountReducer from './slices/accountSlice';
import employeeReducer from './slices/employeeSlice';
import levelGroupsReducer from './slices/levelGroupSlice';
import levelsReducer from './slices/levelSlice';
import statisticsReducer from './slices/statisticSlice';

export const store = configureStore({
  reducer: {
    notification: notificationReducer,
    actionsPopup: actionsPopUpReducer,
    popup: popupReducer,
    auth: authReducer,
    account: accountReducer,
    employee: employeeReducer,
    levelGroup: levelGroupsReducer,
    level: levelsReducer,
    statistic: statisticsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
