import React, { useEffect } from 'react';
import BarChart from '../charts/BarChart';
import { colors } from '../../utils/charts-configuration';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { showPopup } from '../../redux/slices/popupSlice';
import { MessageType } from '../../types';
import { MdHelp } from 'react-icons/md';
import { addNotification } from '../../redux/slices/notificationSlice';
import { formatTime, mapGoalLevelToText } from '../../utils/functions';
import ILevelsTimes from '../../types/ILevelsTimes';
import Loading from '../utils/Loading';

interface IProps {
    title: string;
    data: ILevelsTimes[];
    loading: boolean;
    popupMessage: string;
}

function TimeStatistics({ title, data, loading, popupMessage }: IProps) {
    const dispatch = useAppDispatch();

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>
            <div className='flex items-center justify-between'>
                <h3 className='h3'>{title}</h3>
                <button
                    className='text-primary hover:text-primary-dark'
                    onClick={() =>
                        dispatch(
                            showPopup({
                                type: MessageType.INFO,
                                message: popupMessage,
                            })
                        )
                    }
                >
                    <MdHelp size={24} />
                </button>
            </div>

            <div className='grid grid-cols-3 gap-6 my-3'>
                {loading && (
                    <div className='col-span-3'>
                        <Loading height='300px' />
                    </div>
                )}

                {!loading && data && data.length > 0 && (
                    <>
                        <div className='col-span-1'>
                            <div className='flex flex-col gap-3'>
                                {data.map((item, index) => (
                                    <div key={index} className='flex flex-col gap-1 items-center text-center'>
                                        <span className='font-bold'>{mapGoalLevelToText(item.Goal)}</span>
                                        <div
                                            className='w-3/4 h-2'
                                            style={{ backgroundColor: Object.values(colors)[index] }}
                                        ></div>
                                        <span>{formatTime(item.Time, 'HH:MM:SS')}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='col-span-2 flex flex-col items-end gap-10'>
                            <BarChart
                                datasetLabel='Tempo (minuti)'
                                labels={data.map((item) => mapGoalLevelToText(item.Goal))}
                                data={data.map((item) => Math.round(item.Time / 60))}
                            />

                            {/* <button className='btn'>Statistiche dettagliate</button> */}
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default TimeStatistics;
