export default interface IAnswer {
    Id: number;
    Id_Question: number;
    Text: string;
    IsCorrect: boolean;
}

export const exampleAnswer: IAnswer = {
    Id: 0,
    Id_Question: 0,
    Text: '',
    IsCorrect: false,
};