import React, { useEffect } from 'react';
import Loading from '../components/utils/Loading';

function Videogame() {
    useEffect(() => {
        window.location.href = '/videogame/'; // Redireziona alla build Phaser
    }, []);

    return (
        <>
            <h1 className='h1'>Caricamento videogame in corso...</h1>
            <Loading />
        </>
    );
};

export default Videogame;
