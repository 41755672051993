import React, { useEffect } from 'react';
import PieChart from '../charts/PieChart';
import { colors } from '../../utils/charts-configuration';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { showPopup } from '../../redux/slices/popupSlice';
import { MessageType } from '../../types';
import { MdHelp } from 'react-icons/md';
import { fetchPassedLevels } from '../../redux/slices/statisticSlice';
import { addNotification } from '../../redux/slices/notificationSlice';
import Loading from '../utils/Loading';
import { mapGoalLevelToText } from '../../utils/functions';

function PassedLevels() {
    const dispatch = useAppDispatch();
    const { passedLevels, loadingStatistics, errorStatistics } = useAppSelector((state) => state.statistic);

    useEffect(() => {
        dispatch(fetchPassedLevels());
    }, []);

    useEffect(() => {
        if (errorStatistics) {
            dispatch(addNotification({ message: errorStatistics, type: MessageType.ERROR, tag: 'errorPassedLevels' }));
        }
    }, [errorStatistics]);

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <div className='flex items-center justify-between'>
                <h3 className='h3'>Livelli Superati</h3>
                <button className='text-primary hover:text-primary-dark' onClick={() =>
                    dispatch(
                        showPopup({
                            type: MessageType.INFO,
                            message: 'infoPassedLevels',
                        }))}
                >
                    <MdHelp size={24} />
                </button>
            </div>

            <div className='grid grid-cols-3 gap-6 mt-3'>
                {loadingStatistics && (
                    <div className='col-span-3'>
                        <Loading height='300px' />
                    </div>
                )}

                {!loadingStatistics && passedLevels && passedLevels.length > 0 && (
                    <>
                        <div className='col-span-1'>
                            <div className='flex flex-col gap-3'>
                                {passedLevels.map((item, index) => (
                                    <div key={index} className='flex flex-col gap-1 items-center text-center'>
                                        <span className='font-bold'>{mapGoalLevelToText(item.Goal)}</span>
                                        <div className='w-3/4 h-2' style={{ backgroundColor: Object.values(colors)[index] }}></div>
                                        <span>{item.Passed} / {item.Total} | {Math.round(item.Passed / item.Total * 100)}%</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='col-span-2'>
                            <PieChart
                                datasetLabel='Livelli'
                                labels={passedLevels.map(item => mapGoalLevelToText(item.Goal))}
                                data={passedLevels.map(item => Math.round(item.Passed / item.Total * 100))} // item.Passed
                            />
                        </div>
                    </>
                )}

            </div>

        </section>
    );
}

export default PassedLevels;