import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { showPopup } from '../../redux/slices/popupSlice';
import { MessageType } from '../../types';
import { MdHelp } from 'react-icons/md';
import { fetchGeneralStats } from '../../redux/slices/statisticSlice';
import { addNotification } from '../../redux/slices/notificationSlice';
import Loading from '../utils/Loading';

function GeneralStats() {
    const dispatch = useAppDispatch();
    const { generalStats, loadingStatistics, errorStatistics } = useAppSelector((state) => state.statistic);

    useEffect(() => {
        dispatch(fetchGeneralStats());
    }, []);

    useEffect(() => {
        if (errorStatistics) {
            dispatch(addNotification({ message: errorStatistics, type: MessageType.ERROR, tag: 'errorGeneralStats' }));
        }
    }, [errorStatistics]);

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <div className='flex items-center justify-between'>
                <h3 className='h3'>Statistiche generali</h3>
                <button className='text-primary hover:text-primary-dark' onClick={() =>
                    dispatch(
                        showPopup({
                            type: MessageType.INFO,
                            message: `
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. 
                                Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.
                            `,
                        }))}
                >
                    <MdHelp size={24} />
                </button>
            </div>

            {loadingStatistics && (
                <div className='col-span-3'>
                    <Loading height='300px' />
                </div>
            )}

            {!loadingStatistics && generalStats && (
                <table className='mt-3'>
                    <thead>
                        <tr>
                            <th>Tipo</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='text-wrap'>Numero di dipendenti</td>
                            <td>{generalStats.Employees}</td>
                        </tr>
                        <tr>
                            <td className='text-wrap'>Pacchetti di livelli</td>
                            <td>{generalStats.LevelGroups.Total}</td>
                        </tr>
                        <tr>
                            <td className='text-wrap'>Pacchetti di Livelli in Bozza</td>
                            <td>{generalStats.LevelGroups.Drafts}</td>
                        </tr>
                        <tr>
                            <td className='text-wrap'>Livelli totali</td>
                            <td>{generalStats.Levels.Total}</td>
                        </tr>
                        <tr>
                            <td className='text-wrap'>Livelli in bozza</td>
                            <td>{generalStats.Levels.Drafts}</td>
                        </tr>
                        <tr>
                            <td className='text-wrap'>Livelli completati</td>
                            <td>{generalStats.Levels.Passed}</td>
                        </tr>
                        <tr>
                            <td className='text-wrap'>Livelli falliti</td>
                            <td>{generalStats.Levels.Failed}</td>
                        </tr>
                        <tr>
                            <td className='text-wrap'>Numero medio di Livelli per Pacchetto di Livelli</td>
                            <td>{generalStats.AverageLevelsInGroup}</td>
                        </tr>
                        <tr>
                            <td className='text-wrap'>Tasso di risposta</td>
                            <td>{generalStats.ResponseRate}%</td>
                        </tr>
                    </tbody>
                </table>
            )}

        </section>
    );
}

export default GeneralStats;
