import IQuestion from "./IQuestion";

export default interface IQuiz {
    Id: number;
    Id_Level: number;

    //* NotMapped
    Questions: IQuestion[];
}

export const exampleQuiz: IQuiz = {
    Id: 0,
    Id_Level: 0,

    Questions: [],
};