export default interface IGeneralStats {
    Employees: number;
    LevelGroups: {
        Total: number;
        Drafts: number;
    };
    Levels: {
        Total: number;
        Drafts: number;
        Passed: number;
        Failed: number;
    };
    AverageLevelsInGroup: number;
    ResponseRate: number;
}

export const exampleGeneralStats: IGeneralStats = {
    Employees: 0,
    LevelGroups: {
        Total: 0,
        Drafts: 0,
    },
    Levels: {
        Total: 0,
        Drafts: 0,
        Passed: 0,
        Failed: 0,
    },
    AverageLevelsInGroup: 0,
    ResponseRate: 0,
};