export default interface ILevelsProgress {
    Passed: number;
    Waiting: number;
    Failed: number;
}

export const exampleLevelsProgress: ILevelsProgress = {
    Passed: 0,
    Waiting: 0,
    Failed: 0,
};