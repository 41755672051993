import IAnswer from "./IAnswer";

export default interface IQuestion {
    Id: number;
    Id_Quiz: number;
    Number: number;
    Text: string;

    //* NotMapped
    Answers: IAnswer[];
}

export const exampleQuestion: IQuestion = {
    Id: 0,
    Id_Quiz: 0,
    Number: 0,
    Text: '',

    Answers: [],
};