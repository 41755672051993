import React, { useEffect, useState } from 'react';
import PaginationControls from '../utils/PaginationControls';
import ProgressBar from '../utils/ProgressBar';
import { Link } from 'react-router-dom';
import Tooltip from '../utils/Tooltip';
import { MessageType, PositionType } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchLevelGroupsProgress } from '../../redux/slices/statisticSlice';
import { addNotification } from '../../redux/slices/notificationSlice';
import Loading from '../utils/Loading';

function LevelGroupsProgress() {
    const dispatch = useAppDispatch();
    const { levelGroupsProgress, pages, loadingStatistics, errorStatistics } = useAppSelector((state) => state.statistic);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(6);

    useEffect(() => {
        dispatch(fetchLevelGroupsProgress({ page, limit }));
    }, [dispatch, page, limit]);

    useEffect(() => {
        if (errorStatistics) {
            dispatch(addNotification({ message: errorStatistics, type: MessageType.ERROR, tag: 'errorLevelGroupsProgress' }));
        }
    }, [errorStatistics]);

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <div className='w-full flex items-center justify-between'>
                <h3 className='h3'>Pacchetti di Livelli</h3>
                <div className='flex items-center gap-3'>
                    <span>Mostra</span>
                    <select className='border border-gray-300 rounded-md px-1' value={limit} onChange={(e) => setLimit(Number(e.target.value))}>
                        {[3, 6, 9, 12, 15].map((value, index) => (
                            <option key={index} value={value}>{value}</option>
                        ))}
                    </select>
                </div>
            </div>

            {loadingStatistics && (
                <div className='col-span-3'>
                    <Loading height='300px' />
                </div>
            )}

            {!loadingStatistics && levelGroupsProgress && levelGroupsProgress.length > 0 && (
                <>
                    <table className='mt-3'>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Avanzamento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {levelGroupsProgress.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.Name}</td>
                                    <td>
                                        <Tooltip text={item.Progress + '%'} position={PositionType.TOP}>
                                            <ProgressBar progress={item.Progress + '%'} />
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                    <PaginationControls page={page} totalPages={pages} onPageChange={setPage} />

                    {/* <div className='flex justify-between mt-3'>
                        <Link to={'/levels/groups'} className='btn min-w-fit'>Mostra tutti</Link>
                        <div className='-mt-3'>
                            <PaginationControls page={page} totalPages={pages} onPageChange={setPage} />
                        </div>
                    </div> */}
                </>
            )}

        </section>
    );
}

export default LevelGroupsProgress;