import React, { useEffect, useState } from 'react';
import Stepper from '../utils/Stepper';
import { MdAdd, MdArrowDownward, MdArrowDropDown, MdChevronLeft, MdChevronRight, MdDelete, MdMoveDown, MdMoveUp, MdSave } from 'react-icons/md';
import { GoalLevel, GoalLevelLabels, MessageType, PageType, PageTypeForm } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchLevelGroups } from '../../redux/slices/levelGroupSlice';
import Loading from '../utils/Loading';
import ILevel, { exampleLevel, MAX_ROOMS, MIN_ROOMS } from '../../types/ILevel';
import { addNotification } from '../../redux/slices/notificationSlice';
import RoomUploader from './RoomUploader';
import { createLevel, fetchLevelById, updateLevel } from '../../redux/slices/levelSlice';
import MultiSelect from '../utils/MultiSelect';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ILevelGroup from '../../types/ILevelGroup';
import IRoom from '../../types/IRoom';
import { exampleAnswer } from '../../types/IAnswer';
import IQuestion, { exampleQuestion } from '../../types/IQuestion';
import IQuiz, { exampleQuiz } from '../../types/IQuiz';

interface IProps {
    type: PageTypeForm;
}

// TODO: Impedire la modifica se il livello è pubblicato (anche con link scritto a mano)
function LevelForm({ type }: IProps) {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { loadingLevels, errorLevels } = useAppSelector((state) => state.level);
    const { levelGroups, loadingLevelGroups, errorLevelGroups } = useAppSelector((state) => state.levelGroup);
    const stepBase = ['Informazioni', 'Stanze'];
    const stepQuiz = ['Informazioni', 'Stanze', 'Quiz'];

    const [formData, setFormData] = useState<ILevel>(exampleLevel);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedIdLevelGroups, setSelectedIdLevelGroups] = useState<string[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<(string | null)[]>([]);
    const [existingIdRooms, setExistingIdRooms] = useState<number[]>([]);
    const [roomFiles, setRoomFiles] = useState<(File | null)[]>([]);
    const [updatedRoomIndexes, setUpdatedRoomIndexes] = useState<number[]>([]);
    const [quiz, setQuiz] = useState<IQuiz>(exampleQuiz);
    const [steps, setSteps] = useState<string[]>([]);

    useEffect(() => {
        dispatch(fetchLevelGroups({ page: 1, limit: 100 }))
            .then((result) => {
                if (!fetchLevelGroups.fulfilled.match(result)) {
                    // dispatch(addNotification({ message: 'Pacchetti di livelli caricati', type: MessageType.SUCCESS, tag: 'fetchLevelGroups' }));
                    dispatch(addNotification({ message: 'Errore durante il recupero dei pacchetti di livelli', type: MessageType.ERROR, tag: 'fetchLevelGroups' }));
                }
            })
            .catch((error) => {
                dispatch(addNotification({ message: error ?? 'Errore durante il recupero dei pacchetti di livelli', type: MessageType.ERROR, tag: 'fetchLevelGroups' }));
            });
    }, []);

    useEffect(() => {
        if (formData.Goal === GoalLevel.Quiz) {
            setSteps(stepQuiz);
        } else {
            setSteps(stepBase);
        }
    }, [formData.Goal]);

    useEffect(() => {
        setRoomFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            if (formData.RoomsNumber > prevFiles.length) {
                // Aggiungi slot vuoti per le nuove stanze
                return [...updatedFiles, ...Array(formData.RoomsNumber - prevFiles.length).fill(null)];
            } else if (formData.RoomsNumber < prevFiles.length) {
                // Rimuovi gli slot eccedenti
                return updatedFiles.slice(0, formData.RoomsNumber);
            }
            return updatedFiles;
        });
    }, [formData.RoomsNumber]);

    useEffect(() => {
        if (formData.Goal === GoalLevel.Quiz) {
            setQuiz((prev) => ({
                ...prev,
                ...formData.Quiz,
                Questions: formData.Quiz?.Questions?.map((question) => ({
                    ...question,
                    Answers: question.Answers.map((answer) => ({ ...answer })),
                })) ?? [],
            }));
        }
    }, [formData.Quiz]);

    useEffect(() => {
        if (id) {
            dispatch(fetchLevelById(Number(id)))
                .then((result) => {
                    if (fetchLevelById.fulfilled.match(result)) {
                        const levelData = result.payload;
                        setFormData(levelData);
                        setSelectedIdLevelGroups(levelData.LevelGroups.map((group: ILevelGroup) => group.Id.toString()));
                        setUploadedFiles(levelData.Rooms.map((room: IRoom) => room.File.Name || null));
                        setExistingIdRooms(levelData.Rooms.map((room: IRoom) => room.Id));
                    }
                });
        } else if (type === PageType.SINGLE || type === PageType.EDIT) {
            navigate('/levels');
        }
    }, [id]);

    useEffect(() => {
        if (errorLevels) {
            dispatch(addNotification({ message: errorLevels, type: MessageType.ERROR, tag: 'errorLevels' }));
        }
    }, [errorLevels]);

    useEffect(() => {
        if (errorLevelGroups) {
            dispatch(addNotification({ message: errorLevelGroups, type: MessageType.ERROR, tag: 'errorLevelGroups' }));
        }
    }, [errorLevelGroups]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Dividi il nome per individuare la struttura annidata, se presente
        const nameParts = name.split('.');

        setFormData((prevData) => {
            // Clona l'oggetto corrente di formData
            const updatedData = { ...prevData };

            // Naviga attraverso l'oggetto fino all'ultimo campo
            let currentLevel: any = updatedData;
            for (let i = 0; i < nameParts.length - 1; i++) {
                currentLevel[nameParts[i]] = { ...currentLevel[nameParts[i]] }; // Clona ogni livello per evitare la mutazione dello stato originale
                currentLevel = currentLevel[nameParts[i]];
            }

            const currentField = nameParts[nameParts.length - 1];

            if (currentField === 'Name') {
                formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
            }

            // Aggiorna il valore del campo specifico
            currentLevel[currentField] = formattedValue;

            return updatedData;
        });
    };

    const handleQuizChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        questionIndex: number,
        answerIndex?: number
    ) => {
        const { name, value } = e.target;
        let formattedValue = value;

        setQuiz((prev) => {
            const updatedQuestions = [...prev.Questions];

            if (name === 'Text') {
                formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
            }

            // Se answerIndex è definito, stiamo aggiornando una risposta
            if (answerIndex !== undefined) {
                const updatedAnswers = [...updatedQuestions[questionIndex].Answers];
                updatedAnswers[answerIndex] = {
                    ...updatedAnswers[answerIndex],
                    [name]: formattedValue,
                };
                updatedQuestions[questionIndex].Answers = updatedAnswers;
            } else {
                // Altrimenti, stiamo aggiornando una domanda
                updatedQuestions[questionIndex] = {
                    ...updatedQuestions[questionIndex],
                    [name]: formattedValue,
                };
            }

            return { ...prev, Questions: updatedQuestions };
        });
    };

    const handleRoomFileChange = (roomIndex: number, file: File | null) => {
        setRoomFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles[roomIndex] = file;
            return updatedFiles;
        });

        if (file) {
            setUploadedFiles((prevFiles) => {
                const updatedUploadedFiles = [...prevFiles];
                updatedUploadedFiles[roomIndex] = null; // Rimuove il riferimento al file già caricato
                return updatedUploadedFiles;
            });
            setExistingIdRooms((prevFiles) => {
                const updatedExistingFiles = [...prevFiles];
                updatedExistingFiles.splice(roomIndex, 1); // Rimuove l'ID dalla lista
                return updatedExistingFiles;
            });
            setUpdatedRoomIndexes((prevIndexes) => {
                const updatedIndexes = [...prevIndexes];
                if (!updatedIndexes.includes(roomIndex)) {
                    updatedIndexes.push(roomIndex); // Aggiungi l'indice della stanza modificata
                }
                return updatedIndexes;
            });
        }
    };

    const addQuestion = () => {
        setQuiz((prev) => {
            const updatedQuestions = [...prev.Questions];
            updatedQuestions.push({
                ...exampleQuestion,
                Id_Quiz: prev.Id,
                Number: updatedQuestions.length + 1, // Assegna il numero corretto
                Answers: [
                    { ...exampleAnswer, IsCorrect: true },
                    { ...exampleAnswer, IsCorrect: false },
                ],
            });

            return { ...prev, Questions: updatedQuestions };
        });
    };

    const removeQuestion = (questionIndex: number) => {
        setQuiz((prev) => {
            const updatedQuestions = prev.Questions.filter((_, index) => index !== questionIndex);

            // Aggiorna i numeri dopo la rimozione
            updatedQuestions.forEach((question, index) => {
                question.Number = index + 1;
            });

            return { ...prev, Questions: updatedQuestions };
        });
    };

    const addAnswer = (questionIndex: number) => {
        if (quiz.Questions[questionIndex].Answers.length >= 5) {
            dispatch(addNotification({ message: 'Massimo 5 risposte per domanda', type: MessageType.WARNING, tag: 'errorLevelFields' }));
            return;
        }
        setQuiz((prev) => {
            const updatedQuestions = [...prev.Questions];
            const updatedAnswers = [...updatedQuestions[questionIndex].Answers];

            updatedAnswers.push({
                ...exampleAnswer,
                Id_Question: updatedQuestions[questionIndex].Id,
            });

            updatedQuestions[questionIndex].Answers = updatedAnswers;

            return { ...prev, Questions: updatedQuestions };
        });
    };

    const removeAnswer = (questionIndex: number, answerIndex: number) => {
        setQuiz((prev) => {
            const updatedQuestions = [...prev.Questions];
            const updatedAnswers = updatedQuestions[questionIndex].Answers.filter(
                (_, index) => index !== answerIndex
            );

            updatedQuestions[questionIndex].Answers = updatedAnswers;

            return { ...prev, Questions: updatedQuestions };
        });
    };

    const updateAnswerCorrectness = (questionIndex: number, answerIndex: number) => {
        setQuiz((prev) => {
            const updatedQuestions = [...prev.Questions];
            const updatedAnswers = updatedQuestions[questionIndex].Answers.map((answer, index) => ({
                ...answer,
                IsCorrect: index === answerIndex,
            }));

            updatedQuestions[questionIndex].Answers = updatedAnswers;

            return { ...prev, Questions: updatedQuestions };
        });
    };

    const moveQuestion = (questionIndex: number, direction: 'up' | 'down') => {
        setQuiz((prev) => {
            const updatedQuestions = [...prev.Questions];
            const newIndex = direction === 'up' ? questionIndex - 1 : questionIndex + 1;

            if (newIndex >= 0 && newIndex < updatedQuestions.length) {
                // Scambia le domande
                [updatedQuestions[questionIndex], updatedQuestions[newIndex]] = [
                    updatedQuestions[newIndex],
                    updatedQuestions[questionIndex],
                ];

                // Aggiorna i numeri
                updatedQuestions[questionIndex].Number = questionIndex + 1;
                updatedQuestions[newIndex].Number = newIndex + 1;
            }

            return { ...prev, Questions: updatedQuestions };
        });
    };

    const handleNext = () => {
        if (isValidStep(currentStep)) {
            setCurrentStep(prev => prev + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(prev => prev - 1);
        }
    };

    const isValidStep = (step: number) => {
        if (step >= steps.length) {
            dispatch(addNotification({ message: 'Non ci sono più passaggi successivi', type: MessageType.WARNING, tag: 'errorLevelFields' }));
            return false;
        }

        if (step === 0) {
            if (!formData.Name || !selectedIdLevelGroups || selectedIdLevelGroups.length === 0 || !formData.Goal) {
                dispatch(addNotification({ message: 'Compila tutti i campi obbligatori', type: MessageType.WARNING, tag: 'errorLevelFields' }));
                return false;
            }
            if (formData.RoomsNumber < MIN_ROOMS || formData.RoomsNumber > MAX_ROOMS) {
                dispatch(addNotification({ message: `Il numero di stanze deve essere compreso tra ${MIN_ROOMS} e ${MAX_ROOMS}`, type: MessageType.WARNING, tag: 'errorLevelFieldss' }));
                return false;
            }
        }

        if (step === 1) {
            if (roomFiles.some(file => !file)
                && (type === PageType.CREATE
                    || (type === PageType.EDIT && existingIdRooms.length !== roomFiles.filter(file => !file).length)
                )
            ) {
                dispatch(addNotification({ message: 'Carica tutti i file delle stanze', type: MessageType.WARNING, tag: 'errorLevelFields' }));
                return false;
            }
        }

        if (step === 2) {
            if (formData.Goal !== GoalLevel.Quiz) {
                dispatch(addNotification({ message: 'Il livello non richiede un quiz', type: MessageType.WARNING, tag: 'errorLevelFields' }));
                return false;
            }
            if (!quiz || quiz.Questions.length === 0 || quiz.Questions.some(question => !question.Answers || question.Answers.length < 2)) {
                dispatch(addNotification({ message: 'Il quiz deve avere almeno una domanda con 2 risposte', type: MessageType.WARNING, tag: 'errorLevelFields' }));
                return false;
            }
            if (quiz.Questions.some(question => question.Answers.filter(answer => answer.IsCorrect).length !== 1)) {
                dispatch(addNotification({ message: 'Il quiz deve avere una risposta corretta per ogni domanda', type: MessageType.WARNING, tag: 'errorLevelFields' }));
                return false;
            }
            if (quiz.Questions.some(question => question.Answers.length >= 5)) {
                dispatch(addNotification({ message: 'Massimo 5 risposte per domanda', type: MessageType.WARNING, tag: 'errorLevelFields' }));
                return false;
            }
            if (quiz.Questions.some(question => !question.Text)) {
                dispatch(addNotification({ message: 'Tutte le domande devono avere un testo', type: MessageType.WARNING, tag: 'errorLevelFields' }));
                return false;
            }
            if (quiz.Questions.some(question => question.Answers.some(answer => !answer.Text))) {
                dispatch(addNotification({ message: 'Tutte le risposte devono avere un testo', type: MessageType.WARNING, tag: 'errorLevelFields' }));
                return false;
            }
        }

        return true;
    };

    const handleSave = () => {
        for (let i = 0; i < steps.length; i++) {
            if (!isValidStep(i)) {
                setCurrentStep(i);
                return;
            }
        }

        if (type === PageType.EDIT && id) {
            dispatch(updateLevel({ id: Number(id), level: formData, levelGroups: selectedIdLevelGroups, existingIdRooms, rooms: roomFiles as File[], updatedRoomIndexes, quiz }))
                .then((result) => {
                    if (updateLevel.fulfilled.match(result)) {
                        dispatch(addNotification({ message: 'Livello aggiornato', type: MessageType.SUCCESS }));
                        navigate(`/levels/${id}`);
                    }
                })
                .catch((error) => {
                    dispatch(addNotification({ message: error ?? 'Errore durante l\'aggiornamento del pacchetto di livelli', type: MessageType.ERROR }));
                });
        } else if (type === PageType.CREATE) {
            dispatch(createLevel({ level: formData, levelGroups: selectedIdLevelGroups, rooms: roomFiles as File[], updatedRoomIndexes, quiz }))
                .then((result) => {
                    if (createLevel.fulfilled.match(result)) {
                        dispatch(addNotification({ message: 'Livello creato', type: MessageType.SUCCESS }));
                        navigate('/levels');
                    }
                    else {
                        dispatch(addNotification({ message: result.payload as string ?? 'Errore durante la creazione del livello', type: MessageType.ERROR }));
                    }
                })
                .catch((error) => {
                    dispatch(addNotification({ message: error ?? 'Errore durante la creazione del livello', type: MessageType.ERROR }));
                });
        } else {
            dispatch(addNotification({ message: 'Azione non riconosciuta', type: MessageType.WARNING }));
        }
    };

    return (
        <section>

            <h1 className='h1'>{type === PageType.CREATE ? 'Creazione' : 'Modifica'} Livello</h1>

            <div className='my-6'>
                <Stepper steps={steps} currentStep={currentStep} />
            </div>

            {currentStep === 0 && (
                <div className='w-full flex flex-col gap-4 p-6 bg-white rounded-2xl shadow-lg'>

                    <h3 className='h3'>Informazioni</h3>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='name'>Nome livello</label>
                        <input type='text' id='name' name='Name' className='col-span-6' value={formData.Name} onChange={handleChange} />
                    </div>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='group'>Pacchetti di livelli</label>
                        <div className='col-span-6 flex gap-3'>
                            <MultiSelect
                                options={levelGroups.map(group => ({ value: group.Id.toString(), label: group.Name }))} // Converti i pacchetti di livelli in un formato compatibile con MultiSelect
                                selectedOptions={selectedIdLevelGroups}
                                onChange={setSelectedIdLevelGroups}
                            />
                            {loadingLevelGroups ?
                                <Loading width='30px' height='30px' /> :
                                <Link to={'/levels/groups/create'} className='btn'>Nuovo <MdAdd size={18} /></Link>
                            }
                        </div>
                    </div>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='goal'>Obiettivo</label>
                        <select id='goal' name='Goal' className='col-span-6' value={formData.Goal} onChange={handleChange}>
                            {Object.values(GoalLevel).map((level, index) => (
                                <option key={index} value={level}>{GoalLevelLabels[level]}</option>
                            ))}
                        </select>
                    </div>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='rooms'>Numero di stanze</label>
                        <input type='number' id='rooms' name='RoomsNumber' className='col-span-6' min={MIN_ROOMS} max={MAX_ROOMS} value={formData.RoomsNumber} onChange={handleChange} />
                    </div>

                </div>
            )}

            {currentStep === 1 && (
                <div className='w-full flex flex-col gap-6 p-6 bg-white rounded-2xl shadow-lg'>

                    <h3 className='h3'>Carica stanze</h3>

                    <div className='grid grid-cols-4 gap-4'>
                        {Array.from({ length: formData.RoomsNumber }).map((_, index) => (
                            <RoomUploader key={index} roomIndex={index}
                                title={index === 0 ? 'Stanza 1 - Partenza' : `Stanza ${index + 1}`}
                                onFileChange={handleRoomFileChange}
                                initialFileName={uploadedFiles[index] ?? roomFiles[index]?.name}
                                isUploaded={!!uploadedFiles[index]}
                            />
                        ))}
                    </div>

                </div>
            )}

            {currentStep === 2 && (
                <div className='w-full flex flex-col gap-6 p-6 bg-white rounded-2xl shadow-lg'>

                    <h3 className='h3'>Configurazione Quiz</h3>

                    {quiz.Questions.map((question, questionIndex) => (
                        <div key={questionIndex} className='flex flex-col gap-3'>
                            <div className='form-element !grid !grid-cols-7 gap-4'>
                                <label htmlFor={`question_${questionIndex}`}>Domanda {questionIndex + 1}</label>
                                <div className='col-span-6 flex items-center gap-4'>
                                    <input
                                        type='text'
                                        id={`question_${questionIndex}`}
                                        name='Text'
                                        value={question.Text}
                                        onChange={(e) => handleQuizChange(e, questionIndex)}
                                    />
                                    <div className='flex gap-1'>
                                        <button className='btn btn-primary' onClick={() => moveQuestion(questionIndex, 'up')} disabled={questionIndex === 0}>
                                            <MdMoveUp size={18} />
                                        </button>
                                        <button className='btn btn-primary' onClick={() => moveQuestion(questionIndex, 'down')} disabled={questionIndex === quiz.Questions.length - 1}>
                                            <MdMoveDown size={18} />
                                        </button>
                                    </div>
                                    <button className='btn btn-danger' onClick={() => removeQuestion(questionIndex)}>
                                        <MdDelete size={18} />
                                    </button>
                                </div>
                            </div>
                            {question.Answers.map((answer, answerIndex) => (
                                <div key={answerIndex} className='grid grid-cols-7 gap-4'>
                                    <div className='col-span-6 col-start-2'>
                                        <div className='form-element !grid !grid-cols-7 gap-4'>
                                            <label htmlFor={`answer_${questionIndex}_${answerIndex}`}>
                                                Risposta {answerIndex + 1}
                                            </label>
                                            <div className='col-span-6 flex items-center gap-4'>
                                                <div className='w-full flex relative'>
                                                    <input
                                                        type='text'
                                                        id={`answer_${questionIndex}_${answerIndex}`}
                                                        name='Text'
                                                        value={answer.Text}
                                                        onChange={(e) => handleQuizChange(e, questionIndex, answerIndex)}
                                                    />
                                                    <div className='h-full absolute right-2 flex items-center cursor-pointer hover:opacity-80'
                                                        onClick={() => updateAnswerCorrectness(questionIndex, answerIndex)}>
                                                        {answer.IsCorrect ? '✅' : '❌'}
                                                    </div>
                                                </div>
                                                <button className='btn btn-danger' onClick={() => removeAnswer(questionIndex, answerIndex)}>
                                                    <MdDelete size={18} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className='grid grid-cols-7 gap-4'>
                                <div className='col-span-6 col-start-2'>
                                    <button className='btn btn-sm btn-primary' onClick={() => addAnswer(questionIndex)} disabled={question.Answers.length >= 5}>
                                        Nuova risposta <MdAdd size={18} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}

                    <button className='btn btn-sm btn-primary' onClick={addQuestion}>
                        Nuova domanda <MdAdd size={18} />
                    </button>

                </div>
            )}

            {/* Navigation buttons */}
            <div className='flex justify-between gap-4 py-6'>
                <button className='btn' disabled={currentStep === 0} onClick={handleBack}>
                    <MdChevronLeft size={18} /> Indietro
                </button>
                {currentStep < steps.length - 1 ? (
                    <button className='btn' onClick={handleNext}>
                        Avanti <MdChevronRight size={18} />
                    </button>
                ) : (
                    <button className='btn btn-primary' onClick={handleSave}>
                        Salva <MdSave size={18} />
                    </button>
                )}
            </div>

        </section>
    );
}

export default LevelForm;