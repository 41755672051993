import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import IEmployee from '../../types/IEmployee';
import { SortOrder, UserSex } from '../../types';

interface IState {
  employees: IEmployee[];
  pages: number;
  selectedEmployee: IEmployee | null;
  newEmployee: IEmployee | null
  loadingEmployees: boolean;
  errorEmployees: string | null;
}

const initialState: IState = {
  employees: [],
  pages: 0,
  selectedEmployee: null,
  newEmployee: null,
  loadingEmployees: false,
  errorEmployees: null,
};

// Fetch all employees
export const fetchEmployees = createAsyncThunk(
  'employee/fetchEmployees',
  async (
    { page, limit, searchTerm, sortColumn, sortOrder }: { page: number, limit: number, searchTerm?: string, sortColumn?: string, sortOrder?: SortOrder },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(`/employees${searchTerm ? `?s=${searchTerm}` : ''}`, {
        params: { page, limit, sortColumn, sortOrder },
      });

      // Mappa i dati ricevuti e calcola `Sex` dinamicamente
      const employees = response.data.items.map((employee: IEmployee) => {
        if (employee.UserCompany) {
          const day = parseInt(employee.UserCompany.CF.slice(9, 11), 10);
          employee.UserCompany.Sex = day >= 40 ? UserSex.FEMALE : UserSex.MALE;
        }
        return employee;
      });

      return { ...response.data, items: employees };
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero degli employee');
    }
  }
);

// Fetch a single employee by id
export const fetchEmployeeById = createAsyncThunk(
  'employee/fetchEmployeeById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/employees/${id}`);
      return response.data;
    }
    catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante il recupero dell\'employee');
    }
  }
);

// Create a new employee
export const createEmployee = createAsyncThunk(
  'employee/createEmployee',
  async (newEmployee: IEmployee, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/employees', newEmployee);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante la creazione dell\'employee');
    }
  }
);

// Update an existing employee
export const updateEmployee = createAsyncThunk(
  'employee/updateEmployee',
  async (
    { id, newEmployee }: { id: number, newEmployee: IEmployee },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`/employees/${id}`, newEmployee);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante l\'aggiornamento dell\'employee');
    }
  }
);

// Delete an existing employee
export const deleteEmployee = createAsyncThunk(
  'employee/deleteEmployee',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/employees/${id}`);
      return { ...response.data, Id_Employee: id };
    } catch (error: any) {
      console.log('error', error);
      return rejectWithValue(error.response?.data?.message ?? 'Errore durante la pubblicazione del level');
    }
  }
);

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //* Fetch all employees
      .addCase(fetchEmployees.pending, (state) => {
        state.loadingEmployees = true;
        state.errorEmployees = null;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.loadingEmployees = false;
        state.employees = action.payload.items;
        state.pages = action.payload.pages;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.loadingEmployees = false;
        state.employees = [];
        state.pages = 0;
        state.errorEmployees = action.payload as string || 'Failed to fetch employees';
      })
      //* Fetch a single employee by id
      .addCase(fetchEmployeeById.pending, (state) => {
        state.loadingEmployees = true;
        state.errorEmployees = null;
      })
      .addCase(fetchEmployeeById.fulfilled, (state, action) => {
        state.loadingEmployees = false;
        state.selectedEmployee = action.payload;
      })
      .addCase(fetchEmployeeById.rejected, (state, action) => {
        state.loadingEmployees = false;
        state.selectedEmployee = null;
        state.errorEmployees = action.payload as string || 'Failed to fetch the employee';
      })
      //* Create a new employee
      .addCase(createEmployee.pending, (state) => {
        state.loadingEmployees = true;
        state.errorEmployees = null;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.loadingEmployees = false;
        state.newEmployee = action.payload;
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.loadingEmployees = false;
        state.errorEmployees = action.payload as string || 'Failed to create employee';
      })
      //* Update an existing employee
      .addCase(updateEmployee.pending, (state) => {
        state.loadingEmployees = true;
        state.errorEmployees = null;
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        state.loadingEmployees = false;
        // state.selectedEmployee = action.payload;
      })
      .addCase(updateEmployee.rejected, (state, action) => {
        state.loadingEmployees = false;
        state.errorEmployees = action.payload as string || 'Failed to update employee';
      })
      //* Delete an existing employee
      .addCase(deleteEmployee.pending, (state) => {
        state.loadingEmployees = true;
        state.errorEmployees = null;
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.loadingEmployees = false;
        state.selectedEmployee = null;
        state.employees = state.employees.filter((employee) => employee.Id !== action.payload.Id_Employee);
      })
      .addCase(deleteEmployee.rejected, (state, action) => {
        state.loadingEmployees = false;
        state.errorEmployees = action.payload as string || 'Failed to delete employee';
      });
  },
});

export default employeeSlice.reducer;
