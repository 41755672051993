import React, { useEffect, useRef } from 'react';
import { MdCancel, MdCheck, MdClose } from 'react-icons/md';
import { MessageType } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { hidePopup } from '../../redux/slices/popupSlice';
import { usePopup } from '../../context/PopupContext';

function Popup() {
    const dispatch = useAppDispatch();
    const { executeCallback } = usePopup();
    const { visible, type, message, onConfirmId } = useAppSelector((state) => state.popup);
    const popupRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            // Chiudi il popup se visibile e si clicca fuori (SOLO se non c'e il pulsante di conferma)
            if (visible && popupRef.current && !popupRef.current.contains(e.target as Node) && !onConfirmId) {
                dispatch(hidePopup());
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [visible]);

    const getBgColorByType = () => {
        switch (type) {
            case MessageType.SUCCESS:
                return 'bg-green-500';
            case MessageType.ERROR:
                return 'bg-red-600';
            case MessageType.WARNING:
                return 'bg-yellow-500';
            case MessageType.INFO:
                return 'bg-cyan-500';
            default:
                return 'bg-gray-500';
        }
    }

    const getButtonType = () => {
        switch (type) {
            case MessageType.SUCCESS:
                return 'btn-success';
            case MessageType.ERROR:
                return 'btn-danger';
            case MessageType.WARNING:
                return 'btn-warning';
            case MessageType.INFO:
                return 'btn-info';
            default:
                return 'btn-primary';
        }
    }

    const getMessageContent = (message: string): React.ReactNode => {
        switch (message) {
            case 'infoPassedLevels':
                return (
                    <>
                        Conta quante volte è stata superata una tipologia di livello rispetto al numero totale di tentativi
                        (sulla tipologia stessa)
                    </>
                );
            default:
                return message; // Mostra il messaggio come semplice testo
        }
    };

    if (!visible) return null;
    return (
        <div className='fixed inset-0 flex items-center justify-center bg-black/50 z-[999]'>
            <div ref={popupRef} className='max-w-96 bg-white rounded-2xl shadow-lg'>
                <div className={`w-full h-14 ${getBgColorByType()} rounded-t-2xl relative`}>
                    <button className='absolute top-4 right-4 text-white'>
                        <MdClose size={24} onClick={() => dispatch(hidePopup())} />
                    </button>
                </div>
                <div className='flex flex-col items-center gap-6 px-6 pt-4 pb-6'>
                    <div className='text-lg text-center'>{getMessageContent(message)}</div>

                    {onConfirmId && (
                        <div className='w-full flex items-center justify-between gap-4'>
                            <button className={`btn ${getButtonType()}`}
                                onClick={() => dispatch(hidePopup())}
                            >
                                Annulla <MdCancel size={18} />
                            </button>
                            <button className={`btn ${getButtonType()}`}
                                onClick={() => executeCallback(onConfirmId)}
                            >
                                Conferma <MdCheck size={18} />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Popup;
