import React from 'react';

function ProgressBar({ progress }: { progress: string }) {
    return (
        <div className='relative w-full h-3 bg-gray-300 rounded-lg'>
            <div
                className='absolute h-full bg-green-500 rounded-lg'
                style={{ width: progress }}
            ></div>
        </div>
    );
}

export default ProgressBar;